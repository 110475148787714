import React from "react"
import GetInTouch from "../sections/GetInTouch"
import HeroSection from "../sections/HeroSection"
import SocialShort from "../sections/SocialShortSection"
import Layout from "../components/layout/Layout"
import NewsSection from "../sections/NewsSection"
import HeroVideo from "../video/landingloop-v5.mp4"
import BrandsSection from "../sections/OurBrands"
export default () => {
  return (
    <Layout>
      <HeroSection id="hero"></HeroSection>
      <section>
        <div className="flex flex-col items-center mt-20 mb-20">
          <h1 className="text-5xl xs:text-6xl font-bold">Our Brands</h1>
          <p className="my-8 text-xl">
            interested in one of our services or have input for one of our
            shows?
          </p>
        </div>
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            zIndex: -1,
          }}
          className="mt-4"
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            zIndex: -1,
          }}
          className="mt-4"
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            zIndex: -1,
          }}
          className="mt-4"
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>
      </section>
      <SocialShort id="social"></SocialShort>
      <NewsSection id="news"></NewsSection>
      <GetInTouch id="getintouch"></GetInTouch>
    </Layout>
  )
}
